import * as React from 'react';
import styled from 'styled-components';
import { h1Styles } from './h1';
import { MainA, MainLink } from './MainLink';

export const HomeLink = styled(MainLink)`
  ${h1Styles} 
`;

export const HomeA = styled(MainA)`
  ${h1Styles}
`;

const Box = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

`;

export function HomeNav({ name, description }) {
  return (
    <Box>
      <div>
        <HomeLink to="/">{name}</HomeLink>
      </div>
      <div>
        <MainA textTransform="uppercase">
          [...
          {description}
          ...]
        </MainA>
      </div>
    </Box>
  );
}
