import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import locale from '../localization';
import { HomeNav } from '../components/HomeLink';

const Box = styled.div`
  text-align: center;
  margin: 40px;
  font-size: 10px;
  line-height: 13px;
  font-weight: normal;

  @media (min-width: 750px) {
    font-size: 15px;
    line-height: 19px;
    margin: 40px 120px;
  }

  > h3 {
    margin-top: 25px;
  }
`;

function Art() {
  return (
    <Layout title="impressum">
      <Box>
        <HomeNav name={locale('name')} description="impressum" />
      </Box>
      <Box>
        <h3>Angaben gemäß § 5 TMG</h3>
        <p>Anna Westram</p>
        <p>ANNAWE</p>
        <p>Rungestraße 6</p>
        <p>DE-10179 Berlin</p>

        <h3>Kontakt</h3>
        <p>Telefon: +4917662912145</p>
        <p>E-Mail: text@annawe.de</p>

        <p>Mitglied der Architektenkammer Berlin</p>
        <p>unter der Nummer 18453</p>

        <h3>EU-Streitschlichtung</h3>
        <p>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
        </p>
        <p>https://ec.europa.eu/consumers/odr.</p>
        <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h3>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h3>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h3>Haftung für Inhalte</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
          den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen.Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
          den allgemeinenGesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
          wir diese Inhalte umgehend entfernen.
        </p>

        <h3>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
          Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
          mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
          Verlinkung nicht erkennbar.
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
          Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>

        <h3>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
          dem deutschen Urheberrecht.
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
          bzw. Erstellers.
          Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
          gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
          gekennzeichnet.
          Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
          einen entsprechenden Hinweis.
          Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>

      </Box>
    </Layout>
  );
}

export default Art;
