import styled, { css } from 'styled-components';

export const h1Styles = css`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 51px;
  margin: 0;
`;

const H1 = styled.h1`
  ${h1Styles}
`;

export default H1;
